import { Injectable } from '@angular/core';

import { Prestamo } from './../models/prestamo.model';

import { EnvironmentUrlService } from './../shared/services/environment-url.service';
import { ErrorHandlerService } from '../shared/services/error-handler.service';
import { RepositoryService } from '../shared/services/repository.service';

@Injectable({
  providedIn: 'root'
})
export class PrestamoService {

    constructor( private environmentUrlService: EnvironmentUrlService
        , private repository: RepositoryService, private errorHandler: ErrorHandlerService ) { 
    }

    public obtener(pensionNumero:number) {
      let apiUrl = `${this.environmentUrlService.presimapAPIUrl}/afiliado/${pensionNumero}/prestamos`;
      return this.repository.getData(apiUrl)
    }

    public getMeLoans() {
      let apiUrl = `${this.environmentUrlService.simap42APIUrl}/me/loans`;
      return this.repository.getAuthData(apiUrl)
    }

    public getMeLoan(loan: number){
      let apiUrl = `${this.environmentUrlService.simap42APIUrl}/me/loans/${loan}`;
      return this.repository.getAuthData(apiUrl)
    }

    public obtenerPorId(pensionNumero:number, prestamoId: number) {
      let apiUrl = `${this.environmentUrlService.presimapAPIUrl}/afiliado/${pensionNumero}/prestamo/${prestamoId}`;
      return this.repository.getData(apiUrl)
    }
}
