export class Afiliado {

    //public pensionNumero: number;
    //public nombre: string;
    //public ahorroTotal: number;

    //constructor(afiliado?: any) {
    //    this.pensionNumero = afiliado && afiliado.id || 0;
    //    this.nombre = afiliado && afiliado.nombre || null;
    //    this.ahorroTotal = afiliado && afiliado.ahorroTotal || 0;
    //}

    constructor(public pensionNumero: number, public nombre: string, public ahorroTotal: number) { }

}
