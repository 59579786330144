import { Injectable } from '@angular/core';
import {Observable } from 'rxjs';

import { Afiliado } from './../models/afiliado.model';

import { AuthenticationService } from './authentication.service';
import { EnvironmentUrlService } from './../shared/services/environment-url.service';
import { ErrorHandlerService } from '../shared/services/error-handler.service';
import { RepositoryService } from '../shared/services/repository.service';

@Injectable({
  providedIn: 'root'
})
export class AfiliadoService {
  
  // private apiUrl: string = '';

  public afiliado: Afiliado;
  public errorMessage: string = '';

  constructor( 

      private environmentUrlService: EnvironmentUrlService,
      private repository: RepositoryService, 
      private errorHandler: ErrorHandlerService 
      ) { 
  }

  public obtener(pensionNumero:number) {
    let apiUrl = `${this.environmentUrlService.presimapAPIUrl}/afiliado/${pensionNumero}`;
    return this.repository.getData(apiUrl)
  }

  public getMe() {
    let apiUrl = `${this.environmentUrlService.simap42APIUrl}/me`;
    return this.repository.getAuthData(apiUrl)
  }

  public registrar(pensionNumero:number, appActividadTipoId: number, referencia: number) {

    let appActividad: any = { Fecha: '2019/2/19'
      , PensionNumero: pensionNumero
      , AppActividadTipoId: appActividadTipoId
      , referencia: referencia
    }

    let apiUrl = 'api/appactividad';
    this.repository.create(apiUrl, appActividad)
      .subscribe(res => {
        // $('#successModal').modal();
        console.log( 'repository.create', res );
      },
      (error => {
        this.errorHandler.handleError(error);
        this.errorMessage = this.errorHandler.errorMessage;
        console.log( 'repository.create', this.errorMessage );
      })
    )
  }

  public validar(account:string, password:string) {
    let apiUrl = `${this.environmentUrlService.simap42APIUrl}/login?account=${account}&password=${password}`;
    this.repository.create(apiUrl, {})
      .subscribe(res => {
        // $('#successModal').modal();
        console.log( 'repository.create', res );
        var response = res as any;
        // this.authenticationService.setBearerToken( response.token );        
      },
      (error => {
        this.errorHandler.handleError(error);
        this.errorMessage = this.errorHandler.errorMessage;
        console.log( 'repository.create', this.errorMessage );
      })
    )  
  }

}
