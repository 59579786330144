import { Injectable } from '@angular/core';

import { ErrorHandlerService } from '../shared/services/error-handler.service';
import { RepositoryService } from '../shared/services/repository.service';

@Injectable({
  providedIn: 'root'
})
export class AppActividadService {

  public errorMessage: string = '';

  constructor( private repository: RepositoryService, private errorHandler: ErrorHandlerService ) { }

  public registrar(pensionNumero:number, appActividadTipoId: number, referencia: number) {

    // let appActividad: any = { Fecha: '2019/2/19'
    //   , PensionNumero: pensionNumero
    //   , AppActividadTipoId: appActividadTipoId
    //   , referencia: referencia
    // }

    // let apiUrl = 'api/appactividad';
    // this.repository.create(apiUrl, appActividad)
    //   .subscribe(res => {
    //     // $('#successModal').modal();
    //     console.log( 'repository.create', res );
    //   },
    //   (error => {
    //     this.errorHandler.handleError(error);
    //     this.errorMessage = this.errorHandler.errorMessage;
    //     console.log( 'repository.create', this.errorMessage );
    //   })
    // )
  }
}
